.chartWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.chartHeader {
    margin-bottom: 20px;
}
.chartPercentage {
    margin-bottom: 20px;
    color: black;

    font-weight: 600;
    font-size: xx-large;
}
.chartReportValue {
    color: black;
    margin: 0px 10px;
    font-weight: 600;
    font-size: larger;
}
.chartReportCount {
    display: flex;
}

.apexcharts-xaxistooltip {
    display: none;
}
.apexcharts-yaxis {
    display: none;
}
.apexcharts-svg {
    overflow: visible;
}
